import '../../assets/style/auth/auth-page.scss'
import TervurenLogo from '../../components/TervurenLogo';
const AuthCard = ({children }) => (
    <div className="auth-card">
        <TervurenLogo className="auth-logo" />
        <div className="container">
            {children}
        </div>
    </div>
)
export default AuthCard
