import React from "react";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import useCheckToken from "./hooks/useCheckToken";

// Styles
import "./assets/style/global-styles.scss";
import "./assets/style/components/buttons.scss";
import "./assets/style/components/forms.scss";

export default function App() {
  useCheckToken();
  return (
    <BrowserRouter>
      <div className="App">
        <AppRoutes />
      </div>
    </BrowserRouter>
  );
}
