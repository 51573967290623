import { useDispatch, useSelector } from "react-redux";
import { ForgotPasswordAction } from "../../redux/actions/AuthActions";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import AuthCard from "../../components/auth/AuthCard";
import { useEffect } from "react";
import { resetForgotPasswordState } from "../../redux/actions/AuthActions";

function ForgotPassword() {
  const authResponseSuccess = useSelector(
    (state) => state.userAuth.authResponse.success
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(); // for form control
  const history = useNavigate();
  const dispatch = useDispatch();
  const authResponse = useSelector((state) => state.userAuth.authResponse);

  useEffect(() => {
    // Dispatch the reset action here
    dispatch(resetForgotPasswordState());
  }, [dispatch]);
  // handle submit
  const onSubmit = (data) => {
    var fields = { email: data.email, password: data.password };
    dispatch(ForgotPasswordAction(fields, history));
  };

  return (
    <div className="admin-auth-page">
      <AuthCard className="auth-card">
        {authResponseSuccess ? (
          <div className="row auth-response-text">
            <p className="page-title">
              We have e-mailed your password reset link!
            </p>
            <div className="row">
              <Link to="/login">Return to login</Link>
            </div>
          </div>
        ) : (
          <>
            <p className="page-title">
              Where should we send your recovery email?
            </p>
            <form>
              <div className="row">
                <div
                  className={`input-wrapper ${
                    errors["email"] ? "has-error" : ""
                  }`}
                >
                  <label htmlFor="email"> User Email</label>
                  <input
                    type="email"
                    name="email"
                    placeholder="User Email"
                    {...register("email", {
                      required: true,
                      minLength: 3,
                      maxLength: 35,
                    })}
                  />
                </div>
              </div>

              {/* show submission erros if they exist */}
              {authResponse.success === false ? (
                <div className="row">
                  {" "}
                  <p>{authResponse.message}</p>{" "}
                </div>
              ) : null}

              <div className="row">
                <button
                  type="submit"
                  className="button-blue"
                  onClick={handleSubmit(onSubmit)}
                >
                  Submit
                </button>
              </div>
            </form>
          </>
        )}
      </AuthCard>
    </div>
  ); // [end] return function
} //[end] ForgotPassword
export default ForgotPassword;
