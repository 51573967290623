import React, { useState, useEffect } from 'react';
import { GetLatestTrainings } from '../../apiCalls/TrainingCalls';
import { GetLatestCheckIns } from '../../apiCalls/CheckInCalls';
import { GetAllTrainees } from '../../apiCalls/TraineeCalls';
import { useSelector } from 'react-redux';

function DashboardList() {
  const [trainings, setTrainings] = useState([]);
  const [checkIns, setCheckIns] = useState([]);
  const [enrichedTrainings, setEnrichedTrainings] = useState([]);
  const [enrichedCheckIns, setEnrichedCheckIns] = useState([]);
  const [trainees, setTrainees] = useState([]);
  const [showTrainings, setShowTrainings] = useState(true); // New state
  const location = useSelector((state) => state.location);

  function formatTimeForDisplay(timestamp, type = null) {
    const date = new Date(timestamp);
    const formattedTime = date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });

    // Prefix the time with 'I' or 'O' based on the type

    let prefix = type === '1' ? 'IN' : type === null ? 'OUT ' : '';

    if (type === 1) {
      prefix = 'I : ';
    } else if (type === 2) {
      prefix = '';
    } else {
      prefix = 'O : ';
    }

    return prefix + formattedTime;
  }

  // Fetch data when the component mounts (new each time component renders this way)
  useEffect(() => {
    // Fetch latest trainings data
    async function loadTrainings() {
      const result = await GetLatestTrainings('location', location.id);

      if (result.success) {
        setTrainings(result.data);
      } else {
        console.log(result.message);
      }
    }

    // Fetch latest check-ins data
    async function loadCheckIns() {
      const result = await GetLatestCheckIns('location', location.id);
      if (result.success) {
        setCheckIns(result.data);
      } else {
        console.log(result.message);
      }
    }

    // fetch trainees
    async function loadTrainees() {
      const result = await GetAllTrainees();
      if (result.success) {
        setTrainees(result.data);
      } else {
        console.log(result.message);
      }
    }

    loadTrainings();
    loadCheckIns();
    loadTrainees();
  }, []);

  function formatDate(timestamp) {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.getMonth() + 1; // getMonth() returns 0-11
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  }

  // Enrich trainings data with trainee info
  useEffect(() => {
    // This effect is only for enriching trainings data
    if (trainees.length > 0 && trainings.length > 0) {
      const newEnrichedTrainings = trainings.map((training) => {
        const trainee = trainees.find((t) => t.id === training.trainee_id);
        return {
          ...training,
          trainee_first_name: trainee ? trainee.first_name : '',
          trainee_last_name: trainee ? trainee.last_name : '',
          trainee_phone: trainee ? trainee.phone : '',
          trainee_email: trainee ? trainee.email : '',
          date_completed_formatted: formatDate(
            training.date_completed || training.timestamp
          ), // formatted date
        };
      });

      setEnrichedTrainings(newEnrichedTrainings);
    }
  }, [trainees, trainings]); // Include trainings in dependency array

  // Enrich check ins data with trainee info
  useEffect(() => {
    // This effect is only for enriching trainings data
    if (trainees.length > 0 && checkIns.length > 0) {
      const newEnrichedCheckIns = checkIns.map((checkin) => {
        const trainee = trainees.find((t) => t.id === checkin.trainee_id);
        return {
          ...checkin,
          trainee_first_name: trainee ? trainee.first_name : '',
          trainee_last_name: trainee ? trainee.last_name : '',
          trainee_phone: trainee ? trainee.phone : '',
          trainee_email: trainee ? trainee.email : '',
          date_completed_formatted: formatDate(
            checkin.timestamp || checkin.date_completed
          ), // formatted date
        };
      });

      setEnrichedCheckIns(newEnrichedCheckIns);
    }
  }, [trainees, checkIns]); // Include trainings in dependency array

  // this function is used to group the items by date
  function groupByDate(items) {
    // Sort items by date in descending order before grouping
    const sortedItems = items.sort(
      (a, b) =>
        new Date(b.date_completed || b.timestamp) -
        new Date(a.date_completed || a.timestamp)
    );

    return sortedItems.reduce((acc, item) => {
      const date = new Date(item.date_completed_formatted).toDateString();
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});
  }

  // Determine which list to display (trainings or check-ins) and group them by date
  const displayList = showTrainings
    ? groupByDate(enrichedTrainings)
    : groupByDate(enrichedCheckIns);

  return (
    <div className="dashboard-list">
      {/* Toggle buttons to switch between lists */}
      <div className="list-switch">
        <div
          className={showTrainings ? 'list-option active' : 'list-option'}
          onClick={() => setShowTrainings(true)}
        >
          Latest Trainees
        </div>
        <div
          className={!showTrainings ? 'list-option active' : 'list-option'}
          onClick={() => setShowTrainings(false)}
        >
          Latest CI/CO's
        </div>
      </div>

      {/* Display the list */}
      <div className="list-container">
        {/* map over each date-entry pair in displayList */}
        {Object.entries(displayList).map(([date, items]) => (
          <React.Fragment key={date}>
            {/* Display date header */}
            <div className="list-day">
              {new Date(date).toLocaleDateString('en-US', {
                weekday: 'long',
                month: 'short',
                day: 'numeric',
              })}
            </div>
            <table>
              <tbody>
                {items.map((item) => (
                  <tr key={item.id}>
                    {showTrainings ? (
                      // render latest trainings data
                      <>
                        <td>
                          <p className="letters-circle">
                            {item.trainee_first_name
                              ? item.trainee_first_name.charAt(0)
                              : ''}
                            {item.trainee_last_name
                              ? item.trainee_last_name.charAt(0)
                              : ''}
                          </p>
                        </td>
                        <td>
                          {item.trainee_first_name} {item.trainee_last_name}
                        </td>
                        <td>{item.email}</td>
                        <td className="third-td">
                          {formatTimeForDisplay(
                            item.date_completed || item.timestamp,
                            2
                          )}
                        </td>
                      </>
                    ) : (
                      // render check-ins and check-out data
                      <>
                        <td>
                          <p className="letters-circle">
                            {item.trainee_first_name
                              ? item.trainee_first_name.charAt(0)
                              : ''}
                            {item.trainee_last_name
                              ? item.trainee_last_name.charAt(0)
                              : ''}
                          </p>
                        </td>
                        <td>
                          {item.trainee_first_name} {item.trainee_last_name}
                        </td>
                        <td>{item.trainee_email}</td>
                        <td>
                          {formatTimeForDisplay(
                            item.date_completed || item.timestamp,
                            item.type || '99'
                          )}
                        </td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </React.Fragment>
        ))}
        <div className="filter"></div>
      </div>
    </div>
  );
}

export default DashboardList;
