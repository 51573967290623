import AdminLayout from '../displays/AdminLayout';
import SplitCardCentered from '../../components/SplitCardCentered';
import CompetentTrainersList from '../../components/settings/CompetentTrainersList';
import RegionsList from '../../components/settings/RegionsList';
import LocationsList from '../../components/settings/LocationsList';
import AgreementForm from '../../components/settings/AgreementForm';
import CompaniesList from '../../components/settings/CompaniesList';
// import LanguageSettings from '../../components/settings/LanguageSettings';
// import TrainingVideo from '../../components/settings/TrainingVideo';
import { useSelector } from 'react-redux';
import CompanyInfoForm from '../../components/settings/CompanyInfoForm';

function GlobalSettings() {
	const globalInfo = useSelector((state) => state.global);
  // an array of all the navigation options and it's related component to show on the right side
  const navigationItems = [
    {
      name: 'Global Information',
      component: (
        <CompanyInfoForm 
          	action="update"
			id={globalInfo.id}
          description="Here you can edit the standard company fall back information for the whole Tervuren Application"
        />
      ),
    },
    {
      name: 'Companies List',
      component: (
        <CompaniesList description="This is a list of all of the companies across the whole Tervuren Application" />
      ),
    },
    {
      name: 'Regions List',
      component: (
        <RegionsList
          scope="global"
          description="This is a list of all of the regions across the whole Tervuren Application"
        />
      ),
    },
    {
      name: 'Locations List',
      component: (
        <LocationsList
          scope="global"
          description="This is a list of all of the locations across the whole Tervuren Application"
        />
      ),
    },
    {
      name: 'Competent Trainers',
      component: (
        <CompetentTrainersList
          scope="global"
          description="This is a list of all of the users across the whole Tervuren Application"
        />
      ),
    },
    {
      name: 'Agreement Form',
      component: (
        <AgreementForm
          scope="global"
          description="Here you can edit the standard fall back Training agreement for the whole Tervuren Application"
        />
      ),
    },
    // { name: 'Language Settings', component: <LanguageSettings scope='global' description='Here you can edit the language used in the Tervuren Application'/> },
    // { name: 'Training Video', component: <TrainingVideo scope='global' description='Here you can edit the video used in the Tervuren Training'/> },
  ];

  // return veiw to be rendered
  return (
    <>
      <AdminLayout>
        <SplitCardCentered
          panelName="Global Settings"
          navigationItems={navigationItems}
		  scope='global'
        />
      </AdminLayout>
    </>
  );
}

export default GlobalSettings;
