import "../../assets/style/containers/dashboard.scss";
import { useSelector } from "react-redux";
import DashboardList from "../displays/DashboardList";
import AdminLayout from "../displays/AdminLayout";
import { Link } from "react-router-dom";
import accountIcon from "../../assets/images/icon_my_account.png";
import featureIcon from "../../assets/images/icon_feature_suggestion.png";
import globalIcon from "../../assets/images/icon_global_setting.png";
import companyIcon from "../../assets/images/icon_company_settings.png";
import regionIcon from "../../assets/images/icon_region_settings.png";
import locationIcon from "../../assets/images/icon_location_settings.png";
import messagingIcon from "../../assets/images/comment-regular.png";
import blankTrainingIcon from "../../assets/images/icon_download.png";
import Modal from "../../components/Modal";
import { ViewAndDownloadPdf } from "../Modals/ViewAndDownloadPdf";
import { useState } from "react";
import { SetBlankTrainingAgreement } from "../../apiCalls/TrainingCalls";

import { useAlert } from "../../context/AlertContext";
/*
    1 - 'base', // handles trainings but nothing else | scalehouse clerks or attendants
    2 - 'location-admin', // in charge of location | location manager
    3 - 'regional-admin', // in charge of region | region manager
    4 - 'company-admin', // in charge of companys and locations | stakeholder or general manager
    5 - 'super-admin', // can access anything | Q4 people
*/

function Dashboard() {
  const active_user = useSelector((state) => state.user); // get user info
  const company = useSelector((state) => state.company); // get company info
  const region = useSelector((state) => state.region); // get region info
  const location = useSelector((state) => state.location); // get location info
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const { addAlert } = useAlert();

  // pull in the url for the blank PDF TODO: this should be pulled in from the state or api
  const loadPDF = () => {
    let to_send = {
      company: company,
      region: region,
      location: location,
    };
    SetBlankTrainingAgreement(to_send)
      .then((result) => {
        if (result.success) {
          if (location.blank_pdf) {
            setPdfUrl(location.blank_pdf);
          } else if (region.blank_pdf) {
            setPdfUrl(region.blank_pdf);
          } else if (company.blank_pdf) {
            setPdfUrl(company.blank_pdf);
          } else {
            // if no pdf is found, get a blank one from the api
            setPdfUrl(
              "https://testing-adam.s3.us-east-2.amazonaws.com/tervuren_training_agreement_Adam_OBryan_11_30_23_05_14_45.pdf"
            );
          }
          setIsModalOpen(true);
        }
      })
      .catch((error) => {
        console.error(error);
        addAlert("An error occurred!", "error", true);
      });
  };

  // return veiw to be rendered
  return (
    <>
      <AdminLayout>
        <div className="dashboard-wrapper">
          <div className="dashboard-top">
            <div className="heading-container">
              <p className="letters-circle">{`${active_user.first_name.charAt(
                0
              )}${active_user.last_name.charAt(0)}`}</p>
              <h3>Admin Dashboard</h3>
            </div>
            <div className="text-container">
              <p>
                Welcome to the Tervuren admin dashboard. Here you can find the
                settings for the Training Portal as well as your account. You
                can also access and manage training and visitor records from
                this portal. If you have any questions or suggestions, feel free
                to contact Tervuren support through the 'Feature Suggestion'
                button below.
              </p>
            </div>
            <div className="button-container">
              {/* how many should we limit this too? I think this call should live outside of our global state so that we can get a more up to date list */}
              <Link to="/my-account" className="button-gray">
                <img src={accountIcon} alt="My Account Icon" />
                My Account
              </Link>
              {/* <a href="https://tervuren.app" className="button-gray" target="_blank" rel="noreferrer"><img src={image2} alt="Company Logo" />Documentation</a> */}
              <a
                href="mailto:support@tervuren.app?subject=Feature%20Suggestion"
                className="button-gray"
                target="_blank"
                rel="noreferrer"
              >
                <img src={featureIcon} alt="New Feature Icon" />
                Feature Suggestion
              </a>
            </div>
          </div>

          <div className="dashboard-bottom">
            <div className="heading-container">
              <h2>Quick Actions</h2>
              <p>
                Below contains the most common settings If you need to change
                any information. Make sure to also print a bunch of blank
                training agreeements to have on hand incase there is a power or
                internet outage! You can upload these after connection is
                restored in the training records area which can be found in the
                top navigation bar.
              </p>
            </div>
            <div className="card-container">
              {/* For Q4 Super Users Only */}
              {active_user.role >= 5 && (
                <Link to="/settings/global" className="action-card">
                  <img src={globalIcon} alt="Global Setting Icon" />
                  <h3>Global Settings</h3>
                  <p>
                    This is where to edit, add, or delete all information for
                    the Tervuren application. Only available for Q4 Super
                    Admins.
                  </p>
                </Link>
              )}
              {/* For Company Managers and above Only */}
              {active_user.role >= 4 && (
                <Link to="/settings/company" className="action-card">
                  <img src={companyIcon} alt="Company Settings Icon" />
                  <h3>Company Settings</h3>
                  <p>
                    This is where to edit, add, or delete information for your
                    company. This Defaults to Tervuren's generic settings if not
                    changed. You will want to update the company information to
                    start.
                  </p>
                </Link>
              )}
              {/* For Region Managers and above Only */}
              {active_user.role >= 3 && (
                <Link to="/settings/region" className="action-card">
                  <img src={regionIcon} alt="Region Settings Icon" />
                  <h3>Region Settings</h3>
                  <p>
                    This is where to edit, add, or delete information for your
                    region. This Defaults to the company settings if not
                    changed.
                  </p>
                </Link>
              )}
              {/* For Loction Managers and above Only */}
              {active_user.role >= 2 && (
                <Link to="/settings/location" className="action-card">
                  <img src={locationIcon} alt="Location Settings Icon" />
                  <h3>Location Settings</h3>
                  <p>
                    This is where to edit, add, or delete information for your
                    location. This Defaults to the regional settings if not
                    changed.
                  </p>
                </Link>
              )}
              {active_user.role >= 2 && (
                <Link to="/settings/messaging" className="action-card">
                  <img
                    src={messagingIcon}
                    alt="Location Settings Icon"
                    className="gray-filter"
                  />
                  <h3>Messaging</h3>
                  <p>
                    This is where to edit, add, or delete information for your
                    messages. You can also send messages here.
                  </p>
                </Link>
              )}
              <button className="action-card" onClick={loadPDF}>
                <img src={blankTrainingIcon} alt="Download Agreement Icon" />
                <h3>Print Blank Training Agreement</h3>
                <p>
                  This is where you can print blank Training Agreements. It is a
                  good idea to have some of these in the case of a power or
                  internet outage.{" "}
                </p>
              </button>
              {/* <Link to="/settings/training" className='action-card'>
                            <img src={image4} alt="Company Logo"/>
                                <h3>Training Settings</h3>
                                <p>Lorem ipsum dolor sit amet. Eum dolores dolores sed quaerat voluptas et error vitae est impedit quia ab sint aliquid. </p>
                        </Link> */}
              {/* <Link to="/settings/languages" className='action-card'>
                            <img src={image3} alt="Company Logo"/>
                                <h3>Language Settings</h3>
                                <p>Lorem ipsum dolor sit amet. Eum dolores dolores sed quaerat voluptas et error vitae est impedit quia ab sint aliquid. </p>
                        </Link>   */}
            </div>
          </div>
          <DashboardList />
        </div>
      </AdminLayout>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ViewAndDownloadPdf data={pdfUrl} />
      </Modal>
    </>
  );
}

export default Dashboard;
