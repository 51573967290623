import HttpService from './HttpService';

/*
    function to register the users which takes in credentials as its parameter.
    Inside the function, we create an instance of the HttpService and then use the
    postData function to establish a connection with the server. Then return the response
*/
export const RegisterUserService = (credentials) => {
  const http = new HttpService();
  let signupUrl = 'auth/register'; // set to match the route in the backend api for this call
  return http
    .postData(credentials, signupUrl)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

/*
    function to login a user which takes in credentials as its parameter.
    Inside the function, we create an instance of the HttpService and then use the
    postData function to establish a connection with the server. Then return the response
*/
export const LoginUserService = (credentials) => {
  const http = new HttpService();
  let loginUrl = 'auth/login'; // set to match the route in the backend api for this call
  return http
    .postData(credentials, loginUrl)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const LogOutUserService = () => {
  const http = new HttpService();
  let loginUrl = 'auth/logout'; // set to match the route in the backend api for this call
  const tokenId = 'user-token';
  return http
    .postData(loginUrl, tokenId)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

/*  

*/
export const ForgotPasswordService = (credentials) => {
  const http = new HttpService();
  credentials.app = 'admin';
  let forgotPasswordUrl = `auth/forgot-password`; // set to match the route in the backend api for this call
  return http
    .postData(credentials, forgotPasswordUrl)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

/*

*/
export const ResetPasswordService = (credentials) => {
  const http = new HttpService();
  let resetPasswordUrl = `auth/reset-password`; // set to match the route in the backend api for this call
  return http
    .postData(credentials, resetPasswordUrl)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const TestUserService = (credentials) => {
  const http = new HttpService();
  let resetPasswordUrl = 'users/test'; // set to match the route in the backend api for this call
  return http
    .postData(credentials, resetPasswordUrl)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};
