// CompetentTrainersList.js

import React, { useState, useEffect } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import CardHeading from "../CardHeading";
import { GetAllUsers } from "../../apiCalls/UserCalls";
import Modal from "../Modal";
import DeleteIcon from "../../assets/images/icon-x-indigo.png";
import PenIcon from "../../assets/images/icon-pen-indigo.png";
import ConfirmDelete from "../ConfirmDelete";
import { useSelector } from "react-redux";
import { GetAllCompanies } from "../../apiCalls/CompanyCalls";
import { GetAllRegions } from "../../apiCalls/RegionCalls";
import { GetAllLocations } from "../../apiCalls/LocationCalls";
import CompetentTrainerInfoForm from "../settings/CompetentTrainerInfoForm";

function CompetentTrainersList(props) {
  const scope = props.scope || "company"; // decide which data to use
  const company_id = useSelector((state) => state.company.id);
  const region_id = useSelector((state) => state.region.id);
  const location_id = useSelector((state) => state.location.id);
  const current_user = useSelector((state) => state.user);
  const current_user_role = current_user.role;
  const roles = [
    { id: 1, name: "Trainer" },
    { id: 2, name: "Location Manager" },
    { id: 3, name: "Region Manager" },
    { id: 4, name: "Company Manager" },
    { id: 5, name: "Q4 Super Admin" },
  ];

  const [gridData, setGridData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [modalType, setModalType] = useState("edit");

  const [companyChoices, setCompanyChoices] = useState([]); // for storing companies
  const [regionChoices, setRegionChoices] = useState([]); // for storing regions
  const [locationChoices, setLocationChoices] = useState([]); // for storing locations

  function handleAdd() {
    setModalType("add");
    setIsModalOpen(true);
  }

  function handleEdit(rowData) {
    setSelectedRowId(rowData.id);
    setModalType("edit");
    setIsModalOpen(true);
  }

  function handleDelete(rowData) {
    setSelectedRowId(rowData.id);
    setModalType("delete");
    setIsModalOpen(true);
  }

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // Fetch all companies, regions, and locations
  useEffect(() => {
    async function fetchCompanies() {
      const result = await GetAllCompanies();
      if (result.success) {
        setCompanyChoices(result.data);
      } else {
        console.log(result.message);
      }
    }
    async function fetchRegions() {
      const result = await GetAllRegions();
      if (result.success) {
        setRegionChoices(result.data);
      } else {
        console.log(result.message);
      }
    }
    async function fetchLocations() {
      const result = await GetAllLocations();
      if (result.success) {
        setLocationChoices(result.data);
      } else {
        console.log(result.message);
      }
    }
    fetchCompanies();
    fetchRegions();
    fetchLocations();
  }, []);

  // Load the trainer information
  const loadData = async () => {
    let id = null;
    switch (scope) {
      case "company":
        id = company_id;
        break;
      case "region":
        id = region_id;
        break;
      case "location":
        id = location_id;
        break;
      default:
        id = null;
    }
    let usersFunction = GetAllUsers;
    if (id !== null) {
      usersFunction = () => GetAllUsers(scope, id);
    } else {
      usersFunction = () => GetAllUsers();
    }

    if (usersFunction) {
      usersFunction()
        .then((result) => {
          if (result.success) {
            // Convert the object of users to an array of user objects
            const usersArray = Object.values(result.data);

            // Map company, region, and location IDs to their names
            const updatedData = usersArray.map((user) => {
              const companyName =
                companyChoices.find(
                  (company) =>
                    company.id.toString() === user.company_id.toString()
                )?.name || "N/A";
              const regionName =
                regionChoices.find(
                  (region) => region.id.toString() === user.region_id.toString()
                )?.name || "N/A";

              // Handle multiple location IDs
              let locationNames = "N/A";
              if (user.location_id) {
                const locationIds = user.location_id
                  .split(",")
                  .map((id) => id.trim());
                const matchedLocations = locationChoices.filter((location) =>
                  locationIds.includes(location.id.toString())
                );
                if (matchedLocations.length === 1) {
                  locationNames = matchedLocations[0].name;
                } else if (matchedLocations.length > 1) {
                  locationNames = "Multiple";
                }
              }

              return {
                ...user,
                company_name: companyName,
                region_name: regionName,
                location_names: locationNames,
              };
            });

            setGridData(updatedData);
          } else {
            console.log(result.message);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  // Get the data for the grid and set it
  useEffect(() => {
    loadData();
  }, [
    scope,
    company_id,
    region_id,
    location_id,
    companyChoices,
    regionChoices,
    locationChoices,
  ]);

  // Define grid

  const gridStyle = { minHeight: 350 };

  // Function to render cell content, showing 'N/A' for empty/null values
  const renderCellContent = (value) => value || "N/A";

  // Dynamic columns based on scope
  const dynamicColumns = () => {
    let extraColumns = [];
    if (scope === "global") {
      extraColumns = [
        {
          name: "company_name",
          header: "Company Name",
          defaultFlex: 2,
          render: ({ value }) => renderCellContent(value),
        },
        {
          name: "region_name",
          header: "Region Name",
          defaultFlex: 2,
          render: ({ value }) => renderCellContent(value),
        },
      ];
    } else if (scope === "company") {
      extraColumns = [
        {
          name: "region_name",
          header: "Region Name",
          defaultFlex: 2,
          render: ({ value }) => renderCellContent(value),
        },
      ];
    }
    return extraColumns;
  };

  // Define columns
  const columns = [
    {
      name: "id",
      header: "Id",
      defaultVisible: false,
      type: "number",
      defaultWidth: 100,
    },
    { name: "first_name", header: "First Name", defaultFlex: 2 },
    { name: "last_name", header: "Last Name", defaultFlex: 2 },
    { name: "email", header: "Email", defaultFlex: 2 },
    { name: "phone", header: "Phone", defaultFlex: 2 },
    {
      name: "location_names",
      header: "Locations",
      defaultFlex: 3,
      render: ({ value }) => renderCellContent(value),
    },
    // Add extra columns based on scope
    ...dynamicColumns(),
    {
      name: "role",
      header: "Admin Level",
      defaultFlex: 2,
      render: ({ value }) => {
        const roleName =
          roles.find((role) => role.id === Number(value))?.name || "N/A";
        return roleName;
      },
    },
    {
      header: "Alter",
      render: ({ data }) => {
        const canEdit =
          data.id === current_user.id || current_user_role > data.role;
        const canDelete =
          current_user_role > data.role && data.id !== current_user.id;
        return (
          <div
            style={{ display: "flex", justifyContent: "center", gap: "10px" }}
          >
            {canEdit && (
              <img
                src={PenIcon}
                className="pen-icon"
                alt="Edit"
                onClick={() => handleEdit(data)}
              />
            )}
            {canDelete && (
              <img
                src={DeleteIcon}
                className="delete-icon"
                alt="Delete"
                onClick={() => handleDelete(data)}
              />
            )}
          </div>
        );
      },
      defaultFlex: 1,
    },
  ];

  // Return view to be rendered
  return (
    <>
      <CardHeading
        title="Competent Trainers List"
        description={props.description || ""}
      />
      <div className="card-list-wrapper">
        <ReactDataGrid
          idProperty="id"
          style={gridStyle}
          columns={columns}
          dataSource={gridData}
        />
      </div>
      <div className="add-record-button-wrapper">
        <button
          type="button"
          className="button-blue add-record-button"
          onClick={() => handleAdd()}
        >
          Add Competent Trainer
        </button>
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        {modalType === "add" && (
          <CompetentTrainerInfoForm
            onClose={handleCloseModal}
            action="add"
            id={selectedRowId}
            onSuccessfulOperation={loadData}
          />
        )}
        {modalType === "edit" && (
          <CompetentTrainerInfoForm
            onClose={handleCloseModal}
            action="update"
            id={selectedRowId}
            onSuccessfulOperation={loadData}
          />
        )}
        {modalType === "delete" && (
          <ConfirmDelete
            scope="user"
            onClose={handleCloseModal}
            id={selectedRowId}
            onSuccessfulOperation={loadData}
          />
        )}
      </Modal>
    </>
  );
}

export default CompetentTrainersList;
