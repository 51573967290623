import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Guard, RoleGuard } from "./Guards";

// auth routes
import Login from "./containers/auth/Login";
import Logout from "./containers/auth/Logout";
import ForgotPassword from "./containers/auth/ForgotPassword";
import ResetPassword from "./containers/auth/ResetPassword";

// other routes
import Dashboard from "./containers/pages/Dashboard";
import TrainingRecords from "./containers/pages/TrainingRecords";
import CheckInRecords from "./containers/pages/CheckInRecords";
import LocationSettings from "./containers/pages/LocationSettings";
import RegionSettings from "./containers/pages/RegionSettings";
import CompanySettings from "./containers/pages/CompanySettings";
import GlobalSettings from "./containers/pages/GlobalSettings";
import MyAccount from "./containers/pages/MyAccount";
import Messaging from "./containers/pages/Messaging";
import TraineesList from "./containers/pages/TraineesList";
import { MessagingProvider } from "./context/MessagingProvider";
import LocationSelection from "./containers/pages/LocationSelection";

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />

      {/* routes protected by user token */}
      <Route element={<Guard token="user-token" routeRedirect="/login" />}>
        <Route path="/" element={<Navigate to="/dashboard" replace />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/training-records" element={<TrainingRecords />} />
        <Route path="/checkin-records" element={<CheckInRecords />} />
        <Route path="/my-account" element={<MyAccount />} />
        <Route path="/trainees" element={<TraineesList />} />
        <Route path="/location-selection" element={<LocationSelection />} />
        <Route
          path="/settings/location"
          element={
            <RoleGuard requiredRole={2}>
              <LocationSettings />
            </RoleGuard>
          }
        />
        <Route
          path="/settings/messaging"
          element={
            <RoleGuard requiredRole={2}>
              <MessagingProvider>
                <Messaging />
              </MessagingProvider>
            </RoleGuard>
          }
        />
        <Route
          path="/settings/region"
          element={
            <RoleGuard requiredRole={3}>
              <RegionSettings />
            </RoleGuard>
          }
        />
        <Route
          path="/settings/company"
          element={
            <RoleGuard requiredRole={4}>
              <CompanySettings />
            </RoleGuard>
          }
        />
        <Route
          path="/settings/global"
          element={
            <RoleGuard requiredRole={5}>
              <GlobalSettings />
            </RoleGuard>
          }
        />
        <Route path="/*" element={<Navigate to="/dashboard" replace />} />
      </Route>

      {/* keep this last to catch everything. it will check if logged in first and that will redirect to training instead of login */}
      <Route path="/*" element={<Navigate to="/login" replace />} />
    </Routes>
  );
}
