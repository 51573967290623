// useCheckToken.js
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { CheckTokenAction } from "../redux/actions/AuthActions";

const useCheckToken = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(CheckTokenAction());
    }, 60000);

    return () => clearInterval(interval);
  }, [dispatch]);
};

export default useCheckToken;
