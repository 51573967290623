import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoginAction } from "../../redux/actions/AuthActions";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import AuthCard from "../../components/auth/AuthCard";
import "../../assets/style/auth/auth-page.scss";
import Loader from "../../components/Loader";
import { useState } from "react";
import { useEffect } from "react";

function LoginComponent() {
  const [isLoading, setIsLoading] = useState(false); // for showing Loader
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(); // for form control
  const history = useNavigate();
  const dispatch = useDispatch();
  const authResponse = useSelector((state) => state.userAuth.authResponse);

  useEffect(() => {
    if (authResponse.success) {
      setIsLoading(false); // Hide loader on success
    } else if (authResponse.success === false) {
      setIsLoading(false); // Hide loader on success
    }
  }, [authResponse]);

  // handle submit
  const onSubmit = (data) => {
    setIsLoading(true); // Show loader when form is submitted
    var fields = { email: data.email, password: data.password };
    dispatch(LoginAction(fields, history));
  };

  return (
    <div className="admin-auth-page">
      {isLoading && <Loader />}
      <AuthCard>
        <p className="page-title">Admin Portal Login</p>
        <form>
          <div className="row">
            <div
              className={`input-wrapper ${errors["email"] ? "has-error" : ""}`}
            >
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                placeholder="Enter Email"
                {...register("email", {
                  required: true,
                  minLength: 3,
                  maxLength: 35,
                })}
              />
            </div>
          </div>
          <div className="row">
            <div
              className={`input-wrapper ${
                errors["password"] ? "has-error" : ""
              }`}
            >
              <label htmlFor="password">Password</label>
              <input
                type="password"
                name="password"
                placeholder="Enter Password"
                {...register("password", {
                  required: true,
                  minLength: 3,
                  maxLength: 35,
                })}
              />
            </div>
          </div>

          {/* show submission errors if they exist */}
          {!authResponse.success ? (
            <div className="row">
              {" "}
              <p>{authResponse.message}</p>{" "}
            </div>
          ) : (
            <></>
          )}
          <div className="row">
            <button
              type="submit"
              className="button-blue"
              onClick={handleSubmit(onSubmit)}
            >
              Login
            </button>
          </div>
          <div className="row">
            <Link to="/forgot-password">Forgot Password</Link>
          </div>
        </form>
      </AuthCard>
    </div>
  ); // [end] return function
} //[end] login component

export default LoginComponent;
